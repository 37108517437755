/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shizuru&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.equation span.base {
  white-space: normal;
  width: auto;
  overflow-wrap: anywhere;
  text-align: left;
}

span.mord.mord {
  display: inline-block;
}

.katex-display,
.katex-display .katex {
  white-space: normal;
  width: auto;
  overflow-wrap: anywhere;
  text-align: left;
}

.mord,
.mord .mord .mord.mathnormal {
  font-weight: normal;
  margin-right: 0 !important;
  /* font-style: italic; */
}

.mord.mathnormal {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  /* font-size: 16px; */
}

.katex-html {
  font-size: 16px;
}

/* .equation span.base .mord.text {
  width: auto;
  display: block;
} */
